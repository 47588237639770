import React from 'react';

const Team = () => {
  return (
    <div id="about" className="page-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h4>A nossa Equipa de Advogados</h4>
              <div className="line-dec"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="service-item first-service">
              <img src="img/equipa/trocado.jpg" alt="Fotografia de H. Trocado Moreira" title="Fotografia de H. Trocado Moreira" />
              <h4>H. Trocado Moreira</h4>
              <p>Advogado</p>
              <p><a href="mailto:htrocadomoreira@advogados-pvz.pt" target="_parent">htrocadomoreira@advogados-pvz.pt</a></p>
              <p style={{ marginBottom: '6em' }}>Licenciatura em Direito, UCL</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="service-item second-service">
              <img src="img/equipa/anabela.jpg" alt="Fotografia de Anabela Ribeirinho" title="Fotografia de Anabela Ribeirinho" />
              <h4>Anabela Ribeirinho</h4>
              <p>Advogada e Professora Universitária</p>
              <p><a href="mailto:anabela.ribeirinho@advogados-pvz.pt" target="_parent">anabela.ribeirinho@advogados-pvz.pt</a></p>
              <p>Mestrado em Direito Fiscal, UM</p>
              <p>Pós Grad. em Fashion Law, UCP</p>
              <p>Licenciatura em Direito, UCP</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;