import React from 'react';
import { useEffect } from 'react';
const { useState } = React;


const messages = ["Anos de Atuação", "Advogados", "Clientes"];

function Numbers() {

    useEffect(() => {
        const animateCounters = () => {
            setCounters(prevCounters => 
                prevCounters.map(counter => {
                    let start = counter.start;
                    const end = counter.end;
                    const duration = 8000;
                    const stepTime = Math.abs(Math.floor(duration / (end - start)));
                    
                    const timer = setInterval(() => {
                        start += 1;
                        if (start >= end) {
                            clearInterval(timer);
                            start = end;
                        }
                        setCounters(prevCounters => 
                            prevCounters.map((c, i) => 
                                c.end === end ? { ...c, start } : c
                            )
                        );
                    }, stepTime);
                    
                    return counter;
                })
            );
        };

        animateCounters();
    }, []);
    const [counters, setCounters] = useState([{ start: 0, end: 34 }, { start: 0, end: 3 }, { start: 0, end: 352 }]);
    return <div id="fun-facts">
        <div class="container">
            {counters.map((counter, index) => <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="fact-item">
                    <div class="counter" key={index} data-count="34">{counter.start}</div>
                    <span>{messages[index]}</span>
                </div>
            </div>)}

        </div>
    </div>
}

export default Numbers;