import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Team from './components/Team';
import Contact from './components/Contact';
import WhatWeDo from './components/WhatWeDo';
import Numbers from './components/Numbers';
import { useEffect } from 'react';
import axios from 'axios';
function App() {
  useEffect(() => {
    const interval = setInterval(() => {
      const userId = document.cookie.split('; ').find(row => row.startsWith('user_id='));
      if (userId) {
        const urlParams = new URLSearchParams(window.location.search);
        const gclid = urlParams.get('gclid');
        
        const response = axios.post(`${process.env.REACT_APP_API_URL}/submit-visit`, {
            user_id: userId.split("=")[1],
            gclid,
          }, {
        headers: {
          'Content-Type': 'application/json'
        }
        }).then(response => response.json())
          .then(data => {
            console.log('Success:', data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        
        console.log(`Found user: ${userId.split('=')[1]}`);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Header />
      <HeroSection />
      <Team />
      <WhatWeDo />
      <Numbers />
      <Contact />
    </>
  );
}

export default App;