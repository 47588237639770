import React from 'react';

const HeroSection = () => {
  return (
    <section className="cd-hero">
      <ul className="cd-hero-slider autoplay">
        <li className="selected first-slide">
          <div className="cd-full-width">
            <div className="tm-slide-content-div slide-caption">
              <span>Mais de 30 anos de experiência</span>
              <h2>Acidentes de viação</h2>
              <p>Escritório na Póvoa de Varzim</p>
              <div className="primary-button">
                <a href="#" data-id="contact" className="scroll-link">Contacte-nos</a>
              </div>
            </div>
          </div>
        </li>
        <li className="second-slide">
          <div className="cd-full-width">
            <div className="tm-slide-content-div slide-caption">
              <span>Estamos ao seu dispor</span>
              <h2>Fale connosco</h2>
              <p>Estamos ao seu dispor através do chat, site, email ou telefone</p>
              <div className="primary-button">
                <a href="#" data-id="portfolio" className="scroll-link">Veja as nossas instalações</a>
              </div>
            </div>
          </div>
        </li>
        <li className="third-slide">
          <div className="cd-full-width">
            <div className="tm-slide-content-div slide-caption">
              <span>Entre em contacto connosco</span>
              <h2>Consulta Jurídica</h2>
              <p>Póvoa de Varzim</p>
              <div className="primary-button">
                <a href="#" data-id="contact" className="scroll-link">Contacte-nos</a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cd-slider-nav">
        <nav>
          <span className="cd-marker item-1"></span>
          <ul>
            <li className="selected"><a href="#0"></a></li>
            <li><a href="#0"></a></li>
            <li><a href="#0"></a></li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default HeroSection;