import React from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  return (<div className="header">
    <div className="container">
      <nav role="navigation" className="navbar navbar-inverse">
        <div className="navbar-header"><button id="nav-toggle" type="button" data-toggle="collapse" data-target="#main-nav" className="navbar-toggle"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button><a href="#" className="navbar-brand scroll-top">
          <div className="logo"></div>
        </a></div>
        <div id="main-nav" className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li><Link to="home" smooth={true} duration={500} className="scroll-link">Home</Link></li>
            <li><Link to="about" smooth={true} duration={500} className="scroll-link">Equipa</Link></li>
            <li><Link to="contact" smooth={true} duration={500} className="scroll-link">Contactos</Link></li>
          </ul>
        </div>
      </nav>
    </div></div>);
};

export default Header;