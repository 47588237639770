import React from 'react';

const WhatWeDo = () => {

    return <div id="what-we-do">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="left-text">
                        <h4>Quem Somos</h4>
                        <p>A TRD – Advogados é uma sociedade de advogados sedeada na Póvoa de Varzim, Porto.</p>
                        <p>De cariz nacional e internacional, atua em todas as áreas do Direito ao serviço de um vasto conjunto de clientes, nomeadamente particulares, empresas locais e multinacionais.</p>
                        <p>O nosso lema consiste em conjugar os mais elevados padrões de qualidade a nível internacional com um profundo conhecimento das realidades locais onde atuamos, por forma a prestarmos um serviço pleno e rigoroso aos clientes mais exigentes, sempre com rigor e profissionalismo em busca da melhor solução para os nossos constituintes.</p>
                        <p>A TRD - Advogados disponibiliza um amplo leque de serviços jurídicos aos seus clientes, quer no plano nacional, quer a nível internacional. Os nossos advogados estão aptos a representar os clientes mais exigentes e sofisticados, num conjunto alargado e diversificado de matérias, nas diversas jurisdições.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right-image">
                        <img src="img/what-we-do-right-image_2_fast.png" alt="Fotografias das instalações" title="Instalações na Póvoa de Varzim." />
                        </div>
                </div>
            </div>
        </div>
    </div>
}

export default WhatWeDo;