import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/submit-form`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      alert('Pedido sumbetido com sucesso. Será contactado em breve.');
      console.log('Form submitted successfully:', response.data);
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };



  return (
    <div id="contact" className="page-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h4>Contact Us</h4>
              <div className="line-dec"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea className="form-control" id="message" rows="5" placeholder="Your Message" value={formData.message} onChange={handleChange} required></textarea>
              </div>
              <button type="submit" className="btn btn-primary">Send Message</button>
            </form>
          </div>
          <div className="col-md-6">
            <div className="contact-info">
              <h5>Get in Touch</h5>
              <p>If you have any questions, feel free to reach out to us via the form or through the contact details below.</p>
              <p><strong>Phone:</strong> <a href="tel:+351915711966">+351 911 711 966</a></p>
              <p><strong>Email:</strong> <a href="mailto:info@trd-advogados.com">info@trd-advogados.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;